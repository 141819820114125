import {useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import ActivityCard from "../commons/ActivityCard";
import { selectAllExperiments } from '../redux/experimentsSlice';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Stack } from 'react-bootstrap';
import { Button } from 'react-bootstrap';


function ExperimentList(){

    let [allExp, selectAllExp]= useState([]);
    let [onlineExp, setOnlineExp]= useState([]);
    let [presenciaExp, setPresencialExp]= useState([]);

    let [filterSelection, setFilterSelection]= useState()
    
    let exp = useSelector(selectAllExperiments)[0]

    // useEffect(()=>{
    //     setOnlineExp( exp.filter( e => e.type === 'Online'))
    //     setPresencialExp( exp.filter( e => e.type === 'Presencial'))
    // },[])

    console.log('ONLINE EXP', onlineExp);
    console.log('PRESENCIAL EXP', presenciaExp);

    
    
    if (exp === undefined){
        return null
    }

    let filters =[ 
        { name : 'Online', value: 'online'},
        { name : 'Presencial', value: 'presencial'},
        { name : 'Todos', value: 'todos'}
    ]

    return(
        <Row className={'bg-neutral mt-3 px-4'}> 
            <p className={'noto-sans super-subtitles-size text-secundario fw-bolder p-3'}> Experimentos </p>
            <Row className={'mb-3 d-flex justify-content-center'}>
                <Col sm={'6'} className='text-center'>
                    <ButtonGroup>
                        {filters.map((filter, idx) => (
                            <ToggleButton
                            key={idx}
                            id={`filter-${idx}`}
                            type="radio"
                            variant={filterSelection === filter.value ? 'primary' : 'outline-primary'}
                            name="radio"
                            value={filter.value}
                            checked={filterSelection === filter.value}
                            onChange={(e) => setFilterSelection(e.currentTarget.value)}
                            >
                            {filter.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Col>
            </Row>
                {
                exp.map(a => 
                <Col md={3} className={'d-flex align-items-stretch'}>
                    <ActivityCard 
                        type={a.is_scheduled ? 'Presencial' : 'Online'}                         
                        image= {a.image}
                        title= {a.name}
                        estimated_time= {'x minutos'}
                        text= {a.description}
                        button_text= {a.is_scheduled ? 'Agendar Cita' : 'Comenzar'}
                        date_created= {a.creation_datetime}
                    />
                    </Col> 
                )}
        </Row>
    )
} 

export default ExperimentList;
