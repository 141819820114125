import { configureStore } from "@reduxjs/toolkit";
import experimentsReducer from  './experimentsSlice'
import subjectReducer from "./subjectSlice";
import messageReducer from "./messageSlice";


export const store = configureStore({
    reducer: {
        experiments: experimentsReducer,
        subject: subjectReducer,
        message: messageReducer
    },
})

