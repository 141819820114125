
import Main from "../commons/Main";
import ExperimentList from '../components/experimentLists';
import LoadingData from "../data/loadData";
import Greeting from "../components/GreetingMessage";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Home(){
 
    let HomeContent =
        <Container className={'mt-4 pt-4'}fluid>
            <LoadingData/>
            <Greeting/>
            <ExperimentList/>
        </Container>

return (
        <Main children={HomeContent}/>
)

}

export default Home;