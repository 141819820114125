import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import useAuth from "../hooks/useAuth";
import Stack  from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import Col from 'react-bootstrap/Col'
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { addSubject, selectAllSubject } from "../redux/subjectSlice";


function TopMenu(){

    let auth = useAuth()
    console.log('AUTH', auth);
    let subject = useSelector(selectAllSubject)  

    return(
       <Stack direction="horizontal" gap={3} className={'d-flex justify-content-between p-2 mb-3 bg-secundario fixed-top'}>
        <Col className={'d-flex'}>
            <div className={'w-75  bg-primario logo-bg-transform'}>
                <Image src={"/logo_blueBG.svg"} className={'logo-position'}></Image>
            </div>
        </Col>
        <Col className={'d-flex justify-content-end align-items-center'}>
            <Nav>
                {/* <Nav.Link>
                    <p className={'noto-sans super-subtitles-size text-white mb-0'}>Nosotros</p>
                </Nav.Link>
                <Nav.Link>
                    <p className={'noto-sans super-subtitles-size text-white mb-0'}>Contacto</p>
                </Nav.Link> */}
                {/* <NavDropdown className={'noto-sans super-subtitles-size text-white mb-0'} title={}id="UserMenu">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                </NavDropdown> */}
            </Nav>
        </Col>
       </Stack>
        
    );
}

export default TopMenu;