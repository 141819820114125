import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import axios from '../api/axios';
import LoadingData from '../data/loadData';

import Card from 'react-bootstrap/Card';
import Button  from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert'

const LOGIN_URL = '/api/token/';

export function LoginForm(){

    const navigate = useNavigate();

    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {  

            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ username: user, password: pwd }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Headers': '*' 
                    },
                    withCredentials: false
                }
            );
            console.log('RESPONSE', response?.data);
            console.log(JSON.stringify(response));
            const accessToken = response?.data?.access;
            const refreshToken =  response?.data?.refresh;
            console.log('ACCESS TOKEN', accessToken)
            setAuth({ user, pwd, accessToken, refreshToken });
            setUser('');
            setPwd('');
            setSuccess(true);
            navigate('/dashboard')
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }




    return(
        <Stack gap={5}>
            <Card className={'m-3 p-3 no-border'}>
              <Card.Title> <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>Ya estas registrado?</p></Card.Title>
              <Card.Subtitle><p className={'pt-sans subtitles-size text-center pb-3'} >Ingresa a tu cuenta</p></Card.Subtitle>
              <Card.Body>
                <p ref={errRef} className={errMsg ? "errmsg" : "d-none"} aria-live="assertive">{errMsg}</p>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Control 
                        type="email" 
                        placeholder="Email"
                        id='user'
                        ref={userRef}
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required 
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control 
                        type="password" 
                        placeholder="Password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required 
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className={'text-center'}><a href=''>Olvidaste tu contrasena?</a></p>
                  </Form.Group>
                  <Button className={'w-100 m-auto'} variant="primary" type='submit' > Ingresar </Button>
                </Form>
                </Card.Body>
            </Card>
          </Stack>
    );
}

const REGISTER_URL = 'api/users/'

export function RegisterForm(){

    const navigate = useNavigate();

    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const handleClose = () => {
        setShowRegisterModal(false)
        setErrMsg('')
        setUserEmail('')
        setUserPwd('')
        setMatchPwd('')
    };
    const handleShow = () => setShowRegisterModal(true);

    const [userEmail, setUserEmail] = useState('');
    const [userPwd, setUserPwd] = useState('');
    const [matchPwd, setMatchPwd] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        if (userPwd !== matchPwd){
            setErrMsg('Las contraseñas no coinciden')
        } else {
        const email = userEmail;
        const password = userPwd;
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ username: email, email : email, password: password}),
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Headers': '*' 
                     },
                    withCredentials: true
                }
            );
            console.log(response?.data);
            console.log(response?.accessToken);
            console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUserEmail('');
            setUserPwd('');
            setMatchPwd('');
            handleClose();
            navigate('/complete_registration');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
        }}
    }

    return(
        <Stack gap={5}>
            <Card className={'m-3 p-3 no-border'}>
                {success && <Alert key={'err'} variant={'success'} className={'text-center'}> Usuario registrado correctamente </Alert>}
                <Card.Title> 
                    <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>No estas registrado?</p>
                </Card.Title>
                <Card.Subtitle>
                    <Image src="\iso_whiteBG.svg" className={'iso-logo-card m-auto d-flex'}/>
                </Card.Subtitle>
                <Card.Body>
                    <p className={'pt-sans text-size text-center pb-3 my-3'} >Crea tu cuenta para generar tu perfil </p>
                    <Button className={'w-100 mt-5'} variant="primary" onClick={handleShow}> Registrate</Button>
                </Card.Body>
            </Card>
            <Modal show={showRegisterModal} size={'lg'} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >Ingresa tus datos</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'p-3'}>
                    <Form onSubmit={handleSubmit} className={'mx-3 p-3'}>
                    {errMsg !== "" && <Alert key={'err'} variant={'danger'}>{errMsg}</Alert>}
                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="email" 
                                placeholder="Email"
                                onChange={(e) => setUserEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="password" 
                                placeholder="Contraseña"
                                onChange={(e) => setUserPwd(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="password" 
                                placeholder="Contrasena de nuevo"
                                onChange={(e) => setMatchPwd(e.target.value)} />
                        </Form.Group>
                        <Button className={'w-100 m-auto my-3'} variant="primary" type='submit' > Ingresar </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Stack>
    )
}

