import { useNavigate } from "react-router-dom";

import { CardBody, CardHeader, CardTitle } from "react-bootstrap";
import Card from "react-bootstrap/Card"
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import {faHourglass} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ActivityCard({type, image, title, estimated_time, text, button_text, date_created}) {
    const BASE_URL = 'https://npdev.liaa.dc.uba.ar/'
    const navigate = useNavigate();
    console.log('TITLE', title)
    return(
        <Card className={'section-card shadow-sm m-2 no-border p-2 m-0 no-border no-radius-border'}>
            <CardHeader className={'d-flex align-content-center gradient-bg no-border no-radius-border p-0 '}>
                <small className={'activity-type-flag text-center text-white fw-bold' + (type === 'Online' ? ' bg-red' : ' bg-green')}> {type}</small>    
                <Image src={ BASE_URL + image} className={'activity-card-image my-4 py-3 rounded-top'}/>
            </CardHeader>
            <CardBody className={'d-flex flex-column justify-content-center'}>
                <h4 className={'pt-sans fw-bolder'}>{title}</h4>
                <p><FontAwesomeIcon icon={faHourglass} color="#2DC2E5" size="sm"/><small className={'text-muted px-2 mr-3'}>Tiempo estimado: {estimated_time}</small></p>
                <p className={'text-truncate'}>{text} <br></br> <a className={'mt-0 pt-0 no-decoration text-secundario'}>Ver mas</a> </p>
            </CardBody>
            <Card.Footer className={'no-border bg-white d-flex flex-column justify-content-center'}>
                <Button variant={'secondary'} className={'w-100 m-auto'}>{button_text}</Button>
                <small className={'text-muted text-center pt-3 mb-0 fw-bold '}>{date_created}</small>
            </Card.Footer>
        </Card>
    );
}

export default ActivityCard;